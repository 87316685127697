<template>
  <el-col
    v-pz-loading="loading"
    :span="24"
    data-iframe-height
  >
    <pz-title>
      <span slot="title">
        {{ $t('views.final_doc.title') }}
      </span>
      <span slot="sub_title">
        {{ $t('views.final_doc.sub_title') }}
      </span>
    </pz-title>
    <el-col class="row justify-content-center">
      <el-col
        :span="20"
        :offset="2"
        class="pz-font-size-md"
      >
        <p> {{ $t('views.final.data_received') }} </p>
        <p> {{ $t('views.final.data_received_2') }} </p>
      </el-col>
    </el-col>
  </el-col>
</template>

<script>
import PzLoaderMixins from '@/commons/PzLoaderMixins';
import {setDocSign} from "@/routes/api/views/vales";

export default {
    name: "PzDocusignEnd",
    components: {},
    mixins: [PzLoaderMixins],
    props: {
        envelopId: {
            require: true,
            default: null,
            type: [String, Number]
        },
        alias: {
            require: true,
            default: null,
            type: [String, Number]
        },
    },
    data() {
        return {
            loading: true,
        }
    },
    watch: {},
    created() {
        this.sing().finally(() => {
            this.loaing = false;
        })
    },
    methods: {
        sing() {
            return setDocSign(this.alias, this.envelopId, this.$route.query).
                then(() => {
                    this.ready();
                }).finally(() => {
                    this.loading = false;
                })
        },
        ready() {
            if (window.parentIFrame && window.parentIFrame.getId() === 'docusing') {
                let mens = JSON.stringify({
                    t: 'ready',
                    m: 'Sended success'
                });
                window.parentIFrame.sendMessage(mens);
            }
        }
    }
}
</script>
<style lang="scss" scoped>
iframe {
  border: none !important;
  min-height: 800px;
}


</style>